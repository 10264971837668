html {
  font-size:62.5%;
}



body {
  margin: 0;
  font: 300  11px/1.4 'Helvetica Neue', sans-serif;
  color:#022c43;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}

  
@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}


