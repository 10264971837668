.html{
    margin: 0;
    padding: 0;
}
.text-zone{
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

        // position: absolute;
        // left: 10%;
        // top: 50%;
        transform: translateY(10%);
        transform: translatex(10%);
        // width:50%;
        // vertical-align:middle;
        // display: table-cell;
        max-height: 90%;

        div{
            
            width: 100%;
            padding: 0;
            // display: inline-block;
            // width: min-content;
        }

        h1{

        
         &:nth-of-type(2) {
            margin-left: -20%;
        }
    }
}

.job-container{
    display: inline;
    position: relative;   
    flex: 1 1 50%;
    height: 50%;
    overflow: hidden;
    width: 50%;
    margin: 0;
    padding: 0;
}

.edu-container{
    display: inline;
    position: relative;   
    flex: 1 1 50%;
    height: 50%;
    overflow: hidden;
    width: 50%;
    margin: 0;
    padding: 0;
    margin-left: 20%;
}
.parentjobcontainer{
width: 50%;
margin: 0;
padding: 0;
display: inline;

}
.parentedubcontainer {
    width: 50%;
    margin: -20%;
    padding: 0;
    display: inline;
    
    }
    

p{
    color: rgb(91, 42, 131);
    margin: 0;
}
h3{
    color: aquamarine;
    margin: 0;
}
html {

    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
   
}

li{
    width: 70%;
    margin-top: 2px;
    color:rgb(172, 75, 151);
    font-size: 15px;
}


.image-box{
    position: relative;   
    flex: 1 1 20%;
    // height: 400px;
    overflow: hidden;
    border-radius: 10px;
    // max-width: calc(50% - 10px);
    max-width: 100%;
    margin-top: 25px;
    padding: 0;
    h3{
        color: aquamarine;
        margin: 0;
    }
}

.edu-box {
    position: relative;   
    flex: 1 1 20%;
    // height: 400px;
    overflow: hidden;
    border-radius: 10px;
    // max-width: calc(50% - 10px);
    max-width: 100%;
    margin-top: 25px;
    margin-left: 0%;
    padding: 0;
    h3{
        color: aquamarine;
        margin: 0;
    }
}



.resume-page {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
    margin-top: 20px;

    h1 {
        font-size: 30px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        left:10px;

        &::before{
            // content: '<h1>';
            font-family: 'La Belle Aurore';
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            left:-10px;
            opacity: 0.6;
            line-height: 18px;
        }

        &::after{
            // content: '<h1>';
            font-family:  'La Belle Aurore';
            font-size: 18px;
            line-height: 18px;
            position: absolute;
            margin-left: 10px;
            left:-30px;
            bottom: -20px;
            opacity: 0.6;
            

        }
        div{
            font-size: 12px;
        }
        

    }
    
    p {
        font-style: 12px;
        color:#fff;
        font-family: sans-serif;
        font-weight: 300;
        min-width:fit-content;
        animation: pulse 1s;

        &:nth-of-type(1) {
            animation-delay: 1.2s;
        }
        &:nth-of-type(2) {
            animation-delay: 1.4s;
        }
        &:nth-of-type(3) {
            animation-delay: 1.6s;
        }
    }

   
}

// .content {
    // position: relative;   
    // flex: 1 1 20%;
    // height: 400px;
    // overflow: hidden;
    // border-radius: 10px;
    // // max-width: calc(25% - 10px);
// }

// .text-zone {
//     position: absolute;
//     left: 10%;
//     top: 50%;
//     transform: translateY(-50%);
//     width:50%;
//     vertical-align:middle;
//     display: table-cell;
//     max-height: 90%;
//     display: flex;
//     gap: 15px;
//     flex-wrap: wrap;
//     padding-bottom: 100px;
  
    
//     p{
//         font-size: medium;
//         color: #add8ea ;  
//     }


// }
// .job-container,.edu-container{
// display: flex;
// gap: 15px;
// flex-wrap: wrap;
// padding-bottom: 100px;
// width: 50%;

// position: relative;   
// flex: 1 1 50%;
// height: 400px;
// overflow: hidden;
// border-radius: 10px;
// max-width: calc(50% - 10px);
// }
// // .container {
// //     display: flex;
// //     gap: 15px;
// //     flex-wrap: wrap;
// //     padding-bottom: 100px;
// // }